import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ContactModalComponent>) {}

  ngOnInit(): void {}

  onSubmit() {}

  closeModal() {
    this.dialogRef.close();
  }
}
